div.column {
  flex-grow: 1;
}

@import 'mastodon-light';

.emojione {
-webkit-filter: none;
filter: none;
}
a span {
text-decoration: none!important;
}
a:hover {
background-color: #CCC !important;
}
.status__content a:hover {
border-bottom: 1px solid #000;
text-decoration: none;
background-color: white !important;
}
.status__content a {
border-bottom: 1px dotted #000;
}
a.status__content__spoiler-link,
button.status__content__spoiler-link,
button.status__content__spoiler-link span {
background-color: #ccc!important;
border-bottom: none!important;
color: #323232!important;
margin: 3px 3px;
padding: 3px!important;
text-align: center;
}
a.status__content__spoiler-link:hover {
-moz-box-shadow: 0 0 1px 2px rgba(0, 0, 0, .33);
-webkit-box-shadow: 0 0 1px 2px rgba(0, 0, 0, .33);
box-shadow: 0 0 1px 2px rgba(0, 0, 0, .33);
}
.column-link,
.drawer__tab,
.tabs-bar__link,
.sidebar a:not(.selected),
.column div:not(.status-list__unread-indicator):not(.notifications__unread-indicator):not(.status__video-player-spoiler):not(.spoiler-button):not(.status__video-player-expand):not(.status__video-player-mute):not(.video-player__controls):not(.video-player__seek):not(.video-player__spoiler):not(.video-player__buttons):not(.video-player),
.drawer__inner {
background-color: #f0f0f0;
}
.status__video-player-mute {
bottom: 4px;
top: inherit;
}
.admin-wrapper .simple_form {
background: #f0f0f0;
border-radius: 5px;
padding: 10px;
}
.compose-form__buttons {
background: white!important;
box-shadow: none!important;
}
::-webkit-scrollbar-track:hover {
background: rgba(0, 0, 0, 0.1);
}
.reply-indicator__content {
white-space: normal;
}
.status__content p,
.reply-indicator__content p {
margin-bottom: 5px;
}
div.media-spoiler-video-play-icon {
text-align: center;
width: 36px;
left: 50%;
}
div.media-spoiler-video-play-icon > i.fa-play {
margin-left: 6px;
}
.notification-bar-message,
.notification-bar-title,
.follow-prompt,
.pagination .prev,
.pagination .next {
color: #FFF!important;
}
.muted .status__content p,
.muted .status__content a,
.drawer__inner > div > div > strong,
.account__header span,
div,
.detailed-status__display-name strong,
.setting-text:focus,
.setting-text:active,
.navigation-bar__profile-account {
color: #000;
}
.status__content,
.display-name,
.status__display-name strong,
.account__display-name strong,
a,
.notification,
label span,
.column-settings__section > span,
.status-card p,
.status-card strong,
.getting-started p,
.admin-wrapper .simple_form input,
.admin-wrapper .simple_form textarea,
.admin-wrapper .simple_form label,
.admin-wrapper .content p,
.admin-wrapper .content strong,
.admin .content h2 {
color: #323232;
}
a {
color: #323232!important;
}
.sidebar a.selected,
.react-toggle .react-toggle-track,
.react-toggle-track div {
background-color: #2b90d9!important;
}
.sidebar a:hover,
.react-autosuggest__container input,
.app-holder > div,
.admin-wrapper,
.sidebar-wrapper,
.admin-wrapper .sidebar-wrapper ul ul {
background-color: #fff!important;
}
.form-footer [href*="/settings/preferences"] {
background: none!important;
}
input.search__input,
input.search__input:focus,
.flash-message,
.load-more:hover,
.column,
.landing-strip,
.search-results__header {
background: #f0f0f0;
}
.search__icon .fa,
.search__input,
.account__action-bar__tab span {
color: #000;
}
@media screen and (max-width: 1024px) {
.column:last-child, .drawer:last-child {
margin-right: 0;
}
.column:first-child,
.drawer:first-child {
margin-left: 0;
}
}
@media (min-width: 1025px) {
.column {
margin-bottom: 10px;
margin-right: 5px;
margin-top: 10px;
}
}
@media screen and (min-width: 1900px) {
.column, .drawer {
margin-top: 2vh;
padding-bottom: 0px;
padding-left: 5px;
padding-right: 5px;
padding-top: 0px;
}
}
.spoiler-button,
.status__video-player-spoiler {
z-index: 100;
top: 4px;
left: 4px;
position: absolute;
}
.media-gallery__item-thumbnail {
background-size: auto;
text-align: center;
}
.media-gallery__item-thumbnail img {
object-fit: scale-down;
}
.column-header {
background: transparent;
}

.status,
.detailed-status {
    border: 1px solid #CCCC;
    margin: 8px;
    box-shadow: 4px 2px 2px #888;
    border-radius: 2px;
    background: white !important;
}
.status__content,
.status__display-name,
.states__avatar,
.status__action-bar,
.dropdown,
.dropdown__icon,
.account__avatar-overlay,
.status__content__text .detailed-status__meta,
.detailed-status__meta > *,
.status__content *,
.status__action-bar-dropdown,
.status__action-bar-dropdown div
{
    background: white !important;
}
a.status__display-name:hover,
a.status__relative-time:hover {
    background-color: white !important;
}
.detailed-status__action-bar {
    background: white !important;
    margin: 8px;
    margin-right: 8px;
    box-shadow: 4px 2px 2px #888;
    border: 1px solid #CCC;
    border-radius: 2px;
}
.detailed-status__action-bar > * {
    background: white !important;
}
.account-timeline__header {
    border: 1px solid #CCCC;
    margin: 8px;
    box-shadow: 4px 2px 2px #888;
    border-radius: 2px;
    background: white !important;
}
.account__header,
.account__header > div,
.account__header__content,
.account__header__username,
.account__header__display-name,
.account__disclaimer,
.account__action-bar,
.account__action-bar-dropdown,
.account__action-bar-links .permalink,
.mention,
.status_link {
    background: white !important;
}
.column-header__button,
.column-header__back-button,
.column-back-button {
    background: #f0f0f0;
}
.status.status-direct {
    background: #f0f0f0;
}
.status__prepend * {
    background: #F0F0F0;
}
.status__content a span {
    border-bottom: 1px dotted #000 !important;
}
.status-card,
.status-card__image,
.status-card__content {
    padding: 4px !important;
    background-color: white !important;
}
.drawer__inner.darker {
    background-color: #f0f0f0;
}
.account {
    border: 1px solid #CCCC;
    margin: 8px;
    box-shadow: 4px 2px 2px #888;
    border-radius: 2px;
    background: white !important;
}
div.columns-area
{
    background-color: white;
}

div.ui
{
    background-color: #FFF;
}

.MathJax, .MathJax_Display {
  overflow-x: auto;
  overflow-y: hidden;
}

.account__header__content {
  background: unset!important;
}
